// import axios from 'axios';
// import _ from 'lodash';
// import 'alpinejs';

// window._ = _;
// window.axios = axios;
// window.alpine = alpine;

import * as Turbo from "@hotwired/turbo";
import { Application, Controller } from "@hotwired/stimulus";

window.Turbo = Turbo;
window.Stimulus = Application.start();

class HelloController extends Controller {
  static classes = ["display"];
  static targets = ["menu", "menuMobile"];
  showMenu() {
    console.log("Hello, Stimulus!", this.element);
    this.menuTarget.classList.toggle("hidden");
    this.menuMobileTarget.classList.toggle("hidden");
    this.menuMobileTarget.classList.toggle("block");
  }
}
Stimulus.register("hello", HelloController);

console.log('shit happened here');
